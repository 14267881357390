<template>
  <b-col cols="12">
    <b-card>
      <b-row>
        <b-col
          lg="4"
          md="6"
          cols="12"
          class="cursor-pointer mb-[5px] mt-[5px]"
          @click="$router.push({path:'data-order?tab=order-dibuat'})"
        >
          <div class="border h-100 p-1 rounded-lg">
            <b-overlay
              :show="loadingOrderSummary"
              spinner-variant="primary"
              variant="light"
              blur="0"
              opacity="0.3"
            >
              <p class="mb-1 text-2xl font-semibold">
                Orderan Perlu Dikirim</p>
              <p class="mb-1 text-4xl font-semibold">
                {{ orderanPerluDikirim.toLocaleString('id-ID') }}</p>
              <p class="mb-0">
                Orderan yang perlu kamu kirim</p>
            </b-overlay>
          </div>
        </b-col>
        <b-col
          lg="4"
          md="6"
          cols="12"
          class="cursor-pointer mb-[5px] mt-[5px]"
          @click="$router.push({path:'data-order?tab=retur'})"
        >
          <div class="border h-100 p-1 rounded-lg">
            <b-overlay
              :show="loadingOrderSummary"
              spinner-variant="primary"
              variant="light"
              blur="0"
              opacity="0.3"
            >
              <p class="mb-1 text-2xl font-semibold">
                Orderan Retur</p>
              <p class="mb-1 text-4xl font-semibold">
                {{ orderanRetur.toLocaleString('id-ID') }}</p>
              <p class="mb-0">
                Orderan retur selama 7 hari terakhir</p>
            </b-overlay>
          </div>
        </b-col>
        <b-col
          lg="4"
          md="6"
          cols="12"
          class="cursor-pointer mb-[5px] mt-[5px]"
          @click="$router.push({path:'/ticketing'})"
        >
          <div
            class="border h-100 p-1 rounded-lg"
            :class="needFollowUp.toLocaleString('id-ID') > 0 ? 'blink' : ''"
          >
            <b-overlay
              :show="loadingTicketPartner"
              spinner-variant="primary"
              variant="light"
              blur="0"
              opacity="0.3"
            >
              <p class="mb-1 text-2xl font-semibold">
                Kendala Perlu Ditangani</p>
              <p class="mb-1 text-4xl font-semibold">
                {{ needFollowUp.toLocaleString('id-ID') }}</p>
              <p class="mb-0">
                Jumlah kendala pengiriman yang harus kamu tanggapi dengan segera</p>
            </b-overlay>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>
<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('dashboard', [
      'orderanPerluDikirim',
      'orderanRetur',
      'needFollowUp',
      'loadingOrderSummary',
      'loadingTicketPartner',
    ]),
  },
}
</script>
<style>
@keyframes blink {
  0% { opacity: 1; color: #FFFFFF; }
  50% { opacity: 0.2; color: #000000; }
  100% { opacity: 1; color: #FFFFFF; }
}

.blink {
  animation: blink 4s infinite;
  background-color: #F65A5C;
}
</style>
